import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import NavigationModule from '~/app/core/store/modules/NavigationModule';

import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { VueComponentMixin } from '~/utils/vue-component';
import { defaultLocale, isFullySupportedLocale } from '~/app/localization';

import { MobileNavigation } from '~/components/templates/common';
import { Link } from '~/components/molecules';
import LanguageSelector from '~/components/molecules/languageSelector/LanguageSelector';
import { Image } from '~/components/atoms';
import SearchBar from '~/components/molecules/searchBar/SearchBar';
import NavigationGroup from '~/components/molecules/navigationGroup/NavigationGroup';
import NavigationLink from '~/components/molecules/navigationLink/NavigationLink';

import style from './Header.scss';
import logo from '~/assets/logo.svg';
import logoWhite from '~/assets/logo_white.svg';
import { getCurrentLocale } from '~/utils/localization';
import {
  isNavigationGroup,
  isNavigationLink,
  NavigationElement,
  NavigationLink as NavigationLinkInterface,
} from '~/utils/navigation';
import UserMenu from '../user/UserMenu';

interface HeaderProps {
  isCampaign?: boolean;
}

@Component({
  style,
})
export default class Header
  extends VueComponentMixin<HeaderProps, PrefetchComponent>(Prefetch)
  implements HeaderProps {
  @Prop({ default: false, type: Boolean })
  public isCampaign!: boolean;

  protected rootClass = 'czt-header';

  protected get navigationModule(): NavigationModule {
    return getModule(NavigationModule, this.$store);
  }

  protected get items(): NavigationElement[] {
    return this.navigationModule.main;
  }

  protected get headerImages(): NavigationLinkInterface[] {
    return this.navigationModule.header
      .filter(isNavigationLink)
      .filter((item) => item.image !== undefined);
  }

  protected get logoElement(): JSX.Element {
    const logoImage: JSX.Element = (
      <Image
        src={this.isCampaign ? logoWhite : logo}
        class={`${this.rootClass}__logo`}
        alt='#VisitCzechia Logo'
        lazy={false}
      />
    );

    if (this.$i18n.locale === defaultLocale) {
      return (
        <router-link class='czt-link' to='/'>
          {logoImage}
        </router-link>
      );
    }
    return (
      <Link
        url={'/'}
        forcedLocale={
          isFullySupportedLocale(this.$i18n.locale) ||
          // TODO: Remove when Hungarian added to supported languages
          this.$i18n.locale === 'hu-HU'
            ? undefined
            : 'en-US'
        }
      >
        {logoImage}
      </Link>
    );
  }

  /**
   * Returns header
   * @returns
   */
  public render() {
    return (
      <v-app-bar class={this.rootClass} app height='100px'>
        <v-toolbar-title class='pr-md-3'>
          <v-layout align-center>{this.logoElement}</v-layout>
        </v-toolbar-title>
        {!this.isCampaign && (
          <v-toolbar-items class={`${this.rootClass}__menu-items`}>
            {this.navigationModule.main.map(
              (item: NavigationElement, key: number) => {
                return (
                  <div
                    class={`${this.rootClass}__menu-item`}
                    key={key + item.title}
                  >
                    {isNavigationGroup(item) ? (
                      <NavigationGroup group={item} />
                    ) : (
                      <NavigationLink link={item} />
                    )}
                  </div>
                );
              }
            )}
          </v-toolbar-items>
        )}

        <v-spacer />

        {this.headerImages.length > 0 && (
          <div class={`${this.rootClass}__header-image-links`}>
            {this.headerImages.map(
              (item) =>
                item.image && (
                  <Link
                    url={item.url}
                    class={`${this.rootClass}__header-image-links__item`}
                    target={item.target}
                    forcedLocale={item.forcedLocale}
                  >
                    <Image src={item.image.src} alt={item.image.alt} />
                  </Link>
                )
            )}
          </div>
        )}

        <v-toolbar-items class={`${this.rootClass}__right`}>
          {!this.isCampaign && <SearchBar />}
          <LanguageSelector
            style={{ color: this.isCampaign ? 'white !important' : undefined }}
            currentLanguage={getCurrentLocale(this.$i18n.locale)}
            hideUnallowedLocales={this.isCampaign}
          />
          <UserMenu />
          {!this.isCampaign && (
            <MobileNavigation items={this.navigationModule.main} />
          )}
        </v-toolbar-items>
      </v-app-bar>
    );
  }
}
