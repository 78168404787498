import APIClient from './Client';
import {
  CarouselWidget,
  FaqWidget,
  ForeignRepsMapWidget,
  GalleryWidget,
  GridWidget,
  HtmlContentWidget,
  IframeWidget,
  MapWidget,
  NewsletterFormWidget,
  PanoramicPhotoWidget,
  QuizWidget,
  SafeTravelsWidget,
  SliderWidget,
  SmartGuideWidget,
  TabbedContentWidget,
  TradeOffersWidget,
  VenueFinderWidget,
} from '~/app/core/apiClient/api';
import { CztWidgets } from '~/utils/views/widgets';

export { APIClient };

export function isCarouselWidget(data: any): data is CarouselWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.CAROUSEL
  );
}

export function isGalleryWidget(data: any): data is GalleryWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.GALLERY
  );
}

export function isGridWidget(data: any): data is GridWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.GRID;
}

export function isHtmlWidget(data: any): data is HtmlContentWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.HTML;
}

export function isIframeWidget(data: any): data is IframeWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.IFRAME
  );
}

export function isNewsletterWidget(data: any): data is NewsletterFormWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.NEWSLETTER
  );
}

export function isPanoramaWidget(data: any): data is PanoramicPhotoWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.PANORAMA
  );
}

export function isQuizWidget(data: any): data is QuizWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.QUIZ;
}

export function isRepresentationMapWidget(
  data: any
): data is ForeignRepsMapWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.REPRESENTATION_MAP
  );
}

export function isSafeTravelsWidget(data: any): data is SafeTravelsWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.SAFE_TRAVELS
  );
}

export function isSliderWidget(data: any): data is SliderWidget {
  return (
    data && typeof data === 'object' && data.className === CztWidgets.SLIDER
  );
}

export function isSmartGuideWidget(data: any): data is SmartGuideWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.SMART_GUIDE
  );
}

export function isTabsWidget(data: any): data is TabbedContentWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.TABS;
}

export function isTradeOffersWidget(data: any): data is TradeOffersWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.TRADE_OFFFERS
  );
}

export function isVenueFinderWidget(data: any): data is VenueFinderWidget {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.VENUE_FINDER
  );
}

export function isAPIMapWidget(data: any): data is MapWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.MAP;
}

export function isFaqApiWidget(data: any): data is FaqWidget {
  return data && typeof data === 'object' && data.className === CztWidgets.FAQ;
}
