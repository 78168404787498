import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { getLocaleFromRouter } from '~/app/core/router';
import MapPinsModule, {
  MapPinsRequest,
} from '~/app/core/store/modules/MapPinsModule';
import { Button, Headline } from '~/components/atoms';
import Dialog from '~/components/organisms/dialog/Dialog';
import { ThemeColors } from '~/utils/theme';
import { Ratios } from '~/utils/theme/ratios';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';
import MapStyleSwitch from './MapStyleSwitch';

import style from './MapWidget.scss';
import { MapWidgetProps } from './types';
import { Align } from '~/components/atoms/headline/Headline';
import { MapPin } from '~/components/organisms/map/types';
import { MapStyle, MapVariant } from '~/components/organisms/map/enums';
import { PinCategory } from './enums';
import MapPinFilter from './MapPinFilter';

const rootClass = 'czt-map-widget';

@Component({
  components: {
    'leaflet-map': () => import('~/components/organisms/map/Map'),
  },
  style,
})
export default class MapWidget extends VueComponent<MapWidgetProps>
  implements MapWidgetProps {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true })
  public id!: string;

  @Prop({ required: true })
  public latitude!: number;

  @Prop({ required: true })
  public longitude!: number;

  @Prop({ default: () => [] })
  public preselectedPoi!: MapPin[];

  @Prop({ default: MapStyle.BASIC })
  public defaultStyle!: MapStyle;

  @Prop({ default: false, type: Boolean })
  public detail!: boolean;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: PinCategory.DEFAULT })
  public detailCategory!: PinCategory;

  @Prop({ type: String })
  public title!: string;

  @Prop({ required: true })
  public zoom!: number;

  @Ref('dialogMap')
  protected dialogMap?: any;

  public className = CztWidgets.MAP;

  protected isClient = false;

  protected dialogActive: boolean = false;

  protected mapStyle: MapStyle = this.defaultStyle;

  protected currentZoom: number = this.zoom;

  protected currentCenter: [number, number] = [this.latitude, this.longitude];

  protected loadedPoi: MapPin[] = [];

  protected showLoaderButton: boolean = this.preselectedPoi.length > 0;

  protected selectedCategories: string[] = [];

  protected mapPinsLoading: boolean = false;

  protected get mapPinsModule() {
    return getModule(MapPinsModule, this.$store);
  }

  protected get range(): number {
    switch (this.currentZoom) {
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 70;
      case 11:
        return 35;
      case 12:
        return 16;
      case 13:
        return 8;
      case 14:
        return 4;
      case 15:
      case 16:
        return 2;
      case 17:
      case 18:
      default:
        return 1;
    }
  }

  protected get poiRequestObject(): MapPinsRequest {
    return {
      categories: this.selectedCategories.join(','),
      latitude: this.currentCenter[0],
      locale: getLocaleFromRouter(this.$router),
      longitude: this.currentCenter[1],
      range: this.range,
    };
  }

  protected get combinedPoi() {
    return [
      ...new Map(
        [...this.preselectedPoi, ...this.loadedPoi].map((poi) => [poi.id, poi])
      ).values(),
    ];
  }

  public mounted() {
    this.isClient = true;
    if (this.preselectedPoi.length < 1) {
      this.loadPoi();
    }
  }

  public render() {
    const classes = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      classes.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      classes.push('czt-spacer--collapse-bottom');
    }

    return (
      <div class={classes.join(' ')}>
        <v-container id={this.anchorId} class={{ 'px-0': this.detail }}>
          {this.title && (
            <Headline level={this.detail ? 4 : 2} align={Align.LEFT} underscore>
              {this.title}
            </Headline>
          )}
          <v-responsive
            class={rootClass}
            aspect-ratio={this.detail ? Ratios['1x1'] : Ratios['16x9']}
          >
            {this.isClient && (
              <leaflet-map
                id={this.id}
                center={[this.latitude, this.longitude]}
                places={this.preselectedPoi}
                zoom={this.zoom}
                variant={this.detail ? MapVariant.SIMPLE : MapVariant.MOVABLE}
                detail={this.detail}
                detailCategory={this.detailCategory}
                mapStyle={this.defaultStyle}
              >
                <Button
                  class={`${rootClass}__button`}
                  onClick={() => {
                    this.dialogActive = true;
                    setTimeout(() => {
                      if (this.dialogActive) {
                        this.dialogMap?.map?.mapObject.invalidateSize();
                      }
                    }, 301);
                  }}
                  small
                  slot='bottomright'
                >
                  {this.$t('app.maps.more')}
                </Button>
              </leaflet-map>
            )}
            <Dialog
              active={this.dialogActive}
              onCloseDialog={() => {
                this.dialogActive = false;
              }}
              fullScreen
              style='z-index: 1000'
            >
              {this.isClient && (
                <leaflet-map
                  ref='dialogMap'
                  detail={this.detail}
                  detailCategory={this.detailCategory}
                  id={`${this.id}`}
                  center={this.currentCenter}
                  places={this.combinedPoi}
                  syncTimeout={301}
                  zoom={this.currentZoom}
                  variant={MapVariant.FULL}
                  onUpdateZoom={(zoom: number) => {
                    if (this.currentZoom !== zoom) {
                      this.currentZoom = zoom;
                    }
                  }}
                  onUpdateCenter={(center: [number, number]) => {
                    if (
                      this.currentCenter[0] !== center[0] ||
                      this.currentCenter[1] !== center[1]
                    ) {
                      this.currentCenter = center;
                    }
                  }}
                  mapStyle={this.mapStyle}
                />
              )}
              <MapPinFilter
                loading={this.mapPinsLoading}
                onCategoryChange={(categories) => {
                  this.selectedCategories = categories;
                  this.loadPoi();
                }}
                onLocationChange={(location) => {
                  if (
                    this.currentCenter[0] !== location[0] ||
                    this.currentCenter[1] !== location[1]
                  ) {
                    this.currentCenter = location;
                    this.loadPoi();
                  }
                }}
              />
              <MapStyleSwitch
                currentStyle={this.mapStyle}
                onStyleChange={(selectedStyle) => {
                  this.mapStyle = selectedStyle;
                }}
              />
              {this.showLoaderButton && (
                <Button class={`${rootClass}__load-poi`} onClick={this.loadPoi}>
                  {this.$t('app.maps.loadPoI')}
                </Button>
              )}
              {this.mapPinsLoading && (
                <v-overlay absolute style={{ zIndex: 500 }}>
                  <v-row
                    class='pa-0 ma-0 fill-height'
                    justify='center'
                    align='center'
                  >
                    <v-progress-circular
                      color={ThemeColors.ACCENT}
                      indeterminate
                    />
                  </v-row>
                </v-overlay>
              )}
            </Dialog>
          </v-responsive>
        </v-container>
      </div>
    );
  }

  protected loadPoi() {
    this.mapPinsLoading = true;
    return this.mapPinsModule
      .getMapPins(this.poiRequestObject)
      .then((poi) => {
        this.loadedPoi = poi;
      })
      .finally(() => {
        this.showLoaderButton = false;
        this.mapPinsLoading = false;
      });
  }

  @Watch('currentZoom')
  @Watch('currentCenter', { deep: true })
  protected showFinderButton() {
    this.showLoaderButton = true;
  }
}
